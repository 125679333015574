<template>
  <span/>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import { isSafari } from 'mobile-device-detect';
import { countryCodeForCurrentMarket } from '../detect-market';
import EventBus from '../resources/eventBus';

// {{ transl.App.welcome.interpolate({ name: user.name, lastName: user.last_name }) }}
// DO NOT use transl inside data() for defining strings
// use transl in mounted instead => see HomeInfo

export default {
  name: 'GlobalMixin',
  computed: {
    ...mapGetters(['transl', 'language']),
    wsReady() {
      return this.$socket.readyState === this.$socket.OPEN;
    },
    countryCode() {
      return this.getCountryCode();
    },
    prefCountries() {
      return [this.getCountryCode()];
    },
    whitelistedCountries() {
      // As per: https://app.asana.com/0/inbox/1204013635191982
      // Only allow following countries: Austria, Belgium, Bulgaria, Croatia, Czechia, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Netherlands, Norway, Poland, Portugal, Romania, Serbia, Slovakia, Slovenia, Spain, Sweden, Switzerland, Turkey, Ukraine, United Kingdom 
      return [
        'AT', 'BE', 'BG', 'HR', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE',
        'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'NL', 'NO', 'PL', 'PT',
        'RO', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'TR', 'UA', 'GB'
      ];
    },
    /*deviceModel() {
      return navigator.userAgent;
    }*/
    isMarketPicker() {
      const name = this.$route.name;
      return name === 'MarketPicker';
    },
    isHomePage() {
      const name = this.$route.name;
      return name === 'Home' || name === 'UserStart';
    },
    isOnUserLanding() {
      const name = this.$route.name;
      return name === 'UserStart';
    },
    isOnOtherPage() {
      const path = this.$route.path;
      const name = this.$route.name;
      return path.includes('/info') || path.includes('/faq') || path.includes('/policy') || path.includes('/data') || path.includes('/email') || path.includes('/close') || name === 'UserPV';
    },
    isOnBrandPage() {
      const path = this.$route.path;
      return path.includes('/e/') || path.includes('/c/') || path.includes('/a/');
    },
    isOnUserPage() {
      const path = this.$route.path;
      return (path.includes('/u-p') || path.includes('/u-set') || path.includes('/u-devices') || path.includes('/u-email')
        || path.includes('/u-shared') || path.includes('u-activations') || path.includes('u-history'))
        && !path.includes('/u-p/club/');
    },
    isOnUserClubPage() {
      const path = this.$route.path;
      return path.includes('/u-p/club/');
    },
    isOnUserBrandPage() {
      const path = this.$route.path;
      return path.includes('/u-e/') || path.includes('/u-c/') || path.includes('/u-a/');
    },
    isOnPartnerPage() {
      const path = this.$route.path;
      return path.includes('/partner') || path.includes('/c-') || path.includes('/new-company');
    },
    isOnAdminPage() {
      const path = this.$route.path;
      return path.includes('/admin');
    },
    isOnPvPage() {
      const name = this.$route.name;
      return name === 'UserPV';
    },
    isOnGamePage() {
      const path = this.$route.path;
      return path.includes('/g/');
    },
    isTesting() {
      return this.windowOrigin.includes('test.enginio.io')
        || this.windowOrigin.includes('enginio.io/test')  
        || this.windowOrigin.includes('localhost');
    },
    isDeveloping() {
      return this.windowOrigin.includes('localhost');
    },
    theMarket() {
      return this.$route?.params?.market;
    },
    faqUrl() {
      if (this.localCompany && this.localCompany.faq_url) {
        return this.localCompany.faq_url;
      }
      return this.faqDefaultUrl;
    },
  },
  /*mounted() {
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },*/
  data() {
    return {
      windowOrigin:
        window.origin !== undefined ? `${window.origin}${this.$route?.params?.market ? '/' + this.$route?.params?.market : ''}` : `${window.location.origin}/${this.$route?.params?.market ? '/' + this.$route?.params?.market : ''}`,
      faqDefaultUrl: 'https://intercom-help.eu/enginio/',
      subscribeReady: false,
      isSafari,
      disableGTM: localStorage.getItem('disableGTM') == 'true' ? true : false,
      cookieModalVersion: 'firstCookieModal-v3',
      phoneInputTransl: {},
      supportSubjectOptions: [],
      mobileSmallMaxwidth: 321,
      mobileMaxwidth: 480,
      tabletMinwidth: 481,
      tabletMaxwidth: 990,
      desktopMinwidth: 991,
      desktopSmallWidth: 1023,
      blackboxTimeoutId: null,
      blackboxInterval: 0,
      blackboxIntervalCount: 10,
      /*windowWidth: 0,
      windowHeight: 0,*/
    }
  },
  methods: {
    ...mapMutations({
      setLoadedPageData: 'SET_LOADED_PAGE_DATA',
      setTransl: 'SET_TRANSL',
      setFingerprint: 'SET_FINGERPRINT',
    }),
    /*getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
      this.windowHeight = document.documentElement.clientHeight;
    },*/
    getId(entity) {
      const objectId = entity.unique_name ? entity.unique_name : entity._id;
      const simpleId = entity;
      return objectId || simpleId;
    },
    setLanguage(language) {
      this.setTransl(language);
      localStorage.setItem('localLanguage', language);
      axios.defaults.headers.common['local-language'] = language;
    },
    // Google analytics events
    pushDataLayer({ parentCompany = {}, company = {}, goodiebag = {}, offer = {}, game = {}, action }) {
      if (!this.disableGTM && window && window.dataLayer) {
        const defaultVal = 'Unknown';
        // const ParentCompany = parentCompany.name ? parentCompany.name : (parentCompany._id ? parentCompany._id : parentCompany);
        const ParentCompany = parentCompany._id ? parentCompany._id : parentCompany;
        const CompanyName = company.name ? company.name : (company._id ? company._id : company);
        const CompanyDashboardId = company.dashboard ? company.dashboard : defaultVal;
        const CollectionName = goodiebag.name ? goodiebag.name : (goodiebag._id ? goodiebag._id : goodiebag);
        const ActivationName = offer.first_header ? offer.first_header : (offer._id ? offer._id : offer);
        const GameName = game.name ? game.name : (game._id ? game._id : game);
        const event = 'Enginio View';
        const EventAction = action;
        const userPhone = this.$store.getters.user?.phone_nr;
        let Phone = defaultVal;
        if (userPhone) {
          Phone = Buffer.from(userPhone, 'utf8').toString('base64');
        }

        /*if (ParentCompany == defaultVal || /\d/.test(ParentCompany)) {
          console.error('ParentCompany is an id!!!', ParentCompany);
        }*/

        /*console.log('ParentCompany', ParentCompany);
        console.log('CompanyName', CompanyName);
        console.log('CompanyDashboardId', CompanyDashboardId);
        console.log('CollectionName', CollectionName);
        console.log('ActivationName', ActivationName);
        console.log('EventAction', action);*/
        window.dataLayer.push({
          ParentCompany,
          CompanyName,
          CompanyDashboardId,
          CollectionName,
          ActivationName,
          GameName,
          event,
          EventAction,
          Phone
        });
      } else {
        console.log('did not send GTM event');
      }
    },
    gtag(...arg) {
      if (window && window.dataLayer) {
        window.dataLayer.push(arg);
      }
    },
    openUrl(url, newTab) {
      // Safari does not support window.open new tab!
      if (this.isSafari) {
        return location.assign(url); // not a new tab
      }

      if (!newTab || url.includes('enginio.')) {
        location.assign(url); // not a new tab
      } else {
        window.open(url, '_blank'); // new tab
      }
    },
    setPageTitle(companyName, name) {
      if (companyName && name) {
        document.title = `${companyName} | ${name} | Enginio`;
      } else if (companyName || name) {
        document.title = `${companyName || name} | Enginio`;
      } else {
        document.title = 'Enginio';
      }
    },
    findEntityIssue(error) {
      let redirect = false;
      try {
        const { status } = error.response;
        if (status == 401) {
          this.logoutRedirect();
          return;
        } else {
          redirect = true;
        }
      } catch (err) {
        console.log(err);
        redirect = true;
      }
      if (redirect) {
        this.$router.replace({ name: 'UserStart' });
      }
    },
    logoutRedirect() {
      const path = this.$route.path;
      let pushObj = { name: 'Home' };
      let redirect = false;
      if (this.isOnAdminPage) {
        redirect = true;
      } else if (this.isOnPartnerPage) {
        redirect = true;
        pushObj = { name: 'CompanyHome' };
      } else if (this.isOnUserLanding || this.isOnUserPage || this.isOnUserBrandPage) {
        redirect = true;
        if (path.includes('/u-e/')) {
          const companyId = path.split('/u-e/')[1];
          pushObj = { name: 'HomeCompany', params: { companyId } };
        } else if (path.includes('/u-c/') && this.goodiebag) {
          const goodiebagId = path.split('/u-c/')[1];
          pushObj = { name: 'HomeGoodiebag', params: { goodiebagId } };
        } else if (path.includes('/u-a/') && this.offer) {
          const offerId = path.split('/u-a/')[1];
          pushObj = { name: 'HomeOffer', params: { offerId } };
        }
      }

      if (redirect) {
        // this.$router.push(pushObj); // revert to this, if the page refreshes many times
        this.$router.replace(pushObj);
      }
    },
    getCountryCode () {
      return countryCodeForCurrentMarket(window.location);
    },
    isAdmin(user = { roles: [] }) {
      for (let i = 0; i < user.roles.length; i++) {
        const role = user.roles[i];
        if (role.tenant == 'GLOBAL') {
          return true;
        }
      }
    },
    setBlackBox() {
      this.blackboxTimeoutId = setInterval(() => this.useBlackboxString(), 500);
    },
    useBlackboxString() {
      this.blackboxInterval++;
      const igloo = window.IGLOO;
      if (typeof window.IGLOO.getBlackbox !== 'function') {return;}

      if (this.blackboxInterval >= this.blackboxIntervalCount) {
        console.log('Blackbox timeout');
        clearTimeout(this.blackboxTimeoutId);
      }

      // console.log('igloo:', igloo);
      const bbData = window.IGLOO.getBlackbox();
      this.setFingerprint(bbData.blackbox);
      if (bbData.finished) {
        console.log('Blackbox ready', bbData.blackbox);
        // this.setFingerprint(bbData.blackbox);
        clearTimeout(this.blackboxTimeoutId);
      } else {
        // console.log('Blackbox not ready yet');
      }
    },
    setAlert(message, seconds = 4) {
      EventBus.$emit('setAlert', {
        message,
        seconds,
        close: 'OK',
        top: 0,
        bot: 'unset',
        left: 0,
        right: 0
      });
    },
  }
};
</script>
