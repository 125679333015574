<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <EntityEditor
      v-if="campaign && campaign._id"
      :title= "`Change campaign '${ campaign.name }' (${ campaign.company.name })`"
      :admin="true"
      :entity="campaign"
      entity-type="PVCampaign"
      @eventshowCloneModal="showModalCloneCampaign(true)"
      @publicise="updateData()"/>

    <div v-if="campaign && campaign._id && rates">
      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; padding: 10px; background: white;">
        <div>Total <b>{{ formatNumber(campaign.cashback_total_amount) }} {{ campaign.cashback_currency }}</b></div>
        <div>Estimated Cashbacks (everyone who got the callback) <b>{{ formatNumber(cashbackSum) }} {{ campaign.cashback_currency }}</b></div>
        <div>Estimated Balance <b>{{ formatNumber(campaign.cashback_total_amount - cashbackSum) }} {{ campaign.cashback_currency }}</b></div>
      </div>
    </div>

    <div
      v-if="campaign && campaign._id"
      style="margin-top: 20px;">
      <aList
        entityType="Public Activations"
        columnsWidth="400"
        :entities="campaign.activations"
        :keys="activationsKeys"
        routerName="AdminPVActivation"
        routerId="activationId"/>
    </div>

    <modal
      v-if="showCloneModal"
      :header="{text: `Create copies of '${campaign.name}'`, textPosition: 'center', bgColor: '#F2F2F2'}"
      class="modal-view"
      modalType="fullscreen">
      <slot>
        <form novalidate class="md-layout" @submit.prevent="onSubmit">
          <div v-for="(value, index) in clones" :key="index" class="md-layout-item md-small-size-100">
            <md-card>
              <md-card-content>
                <md-field>
                  <label>Name:</label>
                  <md-input v-model="value.name"></md-input>
                </md-field>
                <md-field>
                  <label>Info:</label>
                  <md-input v-model="value.info"></md-input>
                </md-field>
                <md-field>
                  <label>Company:</label>
                  <md-select v-model="value.company_id">
                    <md-option
                      v-for="company in companies" v-bind:key="company._id"
                      :value="company._id">
                      {{company.name}}
                    </md-option>
                  </md-select>
                </md-field>
              </md-card-content>

              <md-card-actions>
                <button @click="removeClone(index)">
                  <md-icon style="color: #f44336 !important;">delete</md-icon>
                </button>
              </md-card-actions>
            </md-card>
          </div>
        </form>
        <button class="btn btn-activate" @click.prevent="addNewClone">Add more copies</button>
        <div>
          <button class="btn btn-transparent" @click="dismissCloneModal()">Cancel</button>
          <button class="btn btn-activate" @click="createClones()">Save {{ clones.length }} copies</button>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import aList from "../components/AList";

export default {
  name: "AdminPVCampaign",
  components: {
    EntityEditor,
    aList,
  },
  mixins: [BeforeEnterMixin],
  props: ['campaignId'],
  mounted() {
    this.$store.dispatch('pvCampaigns/getAdminPVCampaign', { entityId: this.campaignId }).then(() => {
      this.$store.dispatch('pvCampaigns/getAdminPVExangerates', { from: this.campaign?.cashback_currency || 'SEK' }).then((rates) => {
        this.rates = rates;
        this.loadedPage = true;
      });
    });
  },
  computed: {
    ...mapGetters('pvCampaigns', ['campaign']),
    ...mapGetters('pvCompanies', ["companies"]),
    cashbackSum() {
      let result = 0;
      for (let i = 0; i < this.campaign?.cashbacks?.length; i++) {
        const cashback = this.campaign.cashbacks[i];
        result += cashback.amount * this.rates[cashback.currency];
      }
      return result;
    },
    prefilledClone() {
      return {
        name: "Copy of " + this.campaign.name,
        company_id: this.campaign.company._id,
        info: this.campaign.info
      };
    },
  },
  data() {
    return {
      loadedPage: false,
      rates: {},
      clones: [],
      showCloneModal: false,
      activationsKeys: [
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Info',
          key: 'info',
        },
        {
          title: 'Available for phone_nrs',
          key: 'available_for_phone_nrs',
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    // Set space for every thousand
    formatNumber(value) {
      if (typeof value !== 'number') {
        return 0;
      }
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getCompanies() {
      this.$store.dispatch('pvCompanies/findAdminPVCompanies');
    },
    showModalCloneCampaign(show) {
      // Get list of all companies so admin can clone campaign to another company
      this.getCompanies();

      //pre-fill at least 1 clone for a nice UX
      let starterClone = { ...this.prefilledClone };
      this.clones = [starterClone];
      this.showCloneModal = show;
    },
    dismissCloneModal() {
      // don't leave any left-overs
      this.clones = [];
      //close the dialog
      this.showCloneModal = false;
    },
    addNewClone() {
      // Cloning the Object with Spread Operator
      let exampleClone = { ...this.prefilledClone };
      this.clones.push(exampleClone);
    },
    removeClone(index) {
      this.clones.splice(index, 1);
    },
    createClones() {
      this.$store
      .dispatch("pvCampaigns/cloneAdminPVCampaign", { entityId: this.campaignId, clones: this.clones })
      .then((nrOfCreatedCopies) => {
        this.dismissCloneModal();
        this.setAlert("Succesfully created " + nrOfCreatedCopies + " copies!");
        this.$router.back();
      }).catch((err) => {
        this.setAlert("Failed to create copies with error: " + err);
      });
    },
    updateData() {
      this.loadedPage = false;
      setTimeout(() => {
        this.loadedPage = true;
      }, 200)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .company-item-image{
      width: fit-content;
      height: fit-content;
    }
    .company-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.company-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-company-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
