<template>
  <div
    class="start-page-wrapper"
    v-if="companies.length && loadedPage">
    <div class="start-page">

      <div id="top" class="start-page__header">
        <img src="../assets/images/enginio_header_mobile.png" alt="" class="start-page__header-image-mobile">
        <img src="../assets/images/enginio_header_desktop.png" alt="" class="start-page__header-image-desktop">
        <div class="start-page__header-logo">
          <img src="../assets/images/Enginio_logotype_horizontal_neg.png" alt="">
        </div>
      </div>

      <div class="start-page__live">
        <h3 class="start-page__live-header">{{ transl.Home.live }}</h3>
      </div>
      <aCarousel
        class="start-page__carousel show-tablet-and-desktop"
        :media-list="liveCollectionsMedia"
        :scrollMore="4"
        @slideClick="gotoCurrentEntity($event, 'goodiebag')"/>
      <aCarousel
        class="start-page__carousel show-mobile"
        :media-list="liveCollectionsMedia"
        :scrollMore="2"
        @slideClick="gotoCurrentEntity($event, 'goodiebag')"/>

      <div class="start-page__friends">
        <h3 class="start-page__friends-header">{{ transl.Home.brands }}</h3>
        <div>
          <div class="start-page__friends-circle-wrapper">
            <img
              v-for="company in companies"
              :key="company.icon_1x1?.url || company.icon?.url"
              class="start-page__friends-company-circle"
              :src="company.icon_1x1?.url || company.icon?.url"
              @click="gotoCurrentEntity(company, 'company')"/>
          </div>
        </div>
      </div>

      <div class="start-page__infocards">
        <h3 class="start-page__infocards-header">{{ transl.Home.whatis }}</h3>

        <div class="start-page__infocards-omenginio">
          <img class="start-page__infocards-background" src="../assets/images/enginio_info_card_upptack_enginio.png" alt="">
          <p>{{ transl.Home.about }}</p>
          <p>{{ transl.Home.discover }}</p>
          <a class="btn btn-activate" :href="`${windowOrigin}/info`">{{ transl.Home.read }}</a>
        </div>

        <div class="start-page__infocards-faq">
          <img class="start-page__infocards-background" src="../assets/images/enginio_info_card_allt_du_vill_veta.png" alt="">
          <p>Faq</p>
          <p>{{ transl.Home.know }}</p>
          <a class="btn btn-activate" :href="faqUrl">{{ transl.Home.read }}</a>
        </div>

        <div class="start-page__infocards-share">
          <img class="start-page__infocards-background" src="" alt="">
          <p>{{ transl.Home.invite }}</p>
          <p>Sharing is caring</p>
          <a class="btn btn-activate" :href="`${windowOrigin}/info`">{{ transl.Home.read }}</a>
        </div>
      </div>

      <div  class="start-page__footer"  @mouseenter="bounceAnimation()" @mouseleave="bounceAnimation()">
        <div class="start-page__footer-to-top">
          <a @click="smoothScroll('top')" ><iconComponent symbol="circledArrowUp" fill="#EA7801"/></a>
          <h4>{{ transl.Home.top }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aCarousel from '../components/ACarousel.vue';
import { cloneDeep as _cloneDeep } from 'lodash-es';

export default {
  name: 'UserStart',
  components: {
    aCarousel,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setParentCompany({});
    this.setCompany({});
    this.findNarCompanies('');
    this.findNarGoodiebags('');
    localStorage.removeItem('dashboard');
    this.setPageTitle();
  },
  computed: {
    ...mapGetters([
      'companies',
      'companyQuery',
      'goodiebags',
      'authenticated',
    ]),
  },
  data() {
    return {
      liveCollectionsMedia: [],
      loadedPage: false,
      bounce: false,
    };
  },
  methods: {
    ...mapMutations('parentCompanies', {
      setParentCompany: 'PARENT_COMPANY',
    }),
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
    smoothScroll(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    },
    bounceAnimation() {
      this.bounce = !this.bounce;
    },
    findNarCompanies(query) {
      this.$store.dispatch("findNarCompanies", { query }).then(() => {
        this.loadedPage = true;
      });
    },
    //  && goodiebag.public_landing only show collections that are public_landing = true
    findNarGoodiebags(query) {
      this.$store.dispatch("findNarGoodiebags", { query }).then(() => {
        this.loadedPage = true;
        for (let i = 0; i < this.goodiebags.length; i+=1) {
          const goodiebag = this.goodiebags[i];
          if (goodiebag.public_landing && goodiebag?.landing?.url) {
            const clonedGoodiebag = _cloneDeep(goodiebag);
            this.liveCollectionsMedia.push(clonedGoodiebag.landing);
            const arrLength = this.liveCollectionsMedia.length-1;
            this.liveCollectionsMedia[arrLength]._id = clonedGoodiebag._id;
            this.liveCollectionsMedia[arrLength].name = clonedGoodiebag.name;
            this.liveCollectionsMedia[arrLength].icon = clonedGoodiebag.icon || {};
            this.liveCollectionsMedia[arrLength].unique_name = clonedGoodiebag.unique_name;
            this.liveCollectionsMedia[arrLength].quick_text = clonedGoodiebag.company.name;
          }
        }
      });
    },
    gotoCurrentEntity(entity, entityType) {
      if (entityType == 'goodiebag') {
        this.$router.push({
          name: 'UserGoodiebag',
          params: { goodiebagId: this.getId(entity) }
        });
      } else {
        if (entity.custom_url) {
          this.openUrl(entity.custom_url, false);
        } else {
          this.$router.push({
            name: 'UserCompany',
            params: { companyId: this.getId(entity) }
          });
        }
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/UserStart";
</style>
