<template>
  <div
    class="input-field--container"
    :class="containerClasses"
    @click="$emit('inputClick')"
    @keydown="keyListener($event)">

    <iconComponent
      v-if="leadingIcon" :symbol="leadingIcon"
      class="leadingIcon"
      :class="{ 'clickable': clickableLeadingIcon }"
      width="16px" :fill="leadingIconFill" :stroke="leadingIconStroke"
      @click.stop="$emit('leadingIconClick')"/>

    <iconComponent
      v-if="trailingIcon" :symbol="trailingIcon"
      class="trailingIcon"
      :class="{ 'clickable': clickableTrailingIcon }"
      width="16px" :fill="trailingIconFill" :stroke="trailingIconStroke"
      @click.stop="$emit('trailingIconClick')"/>

    <textarea
      v-if="inputType === 'textarea'"
      :name="label"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      @change="onChange($event)"
      :value="value"
      class="input-field__textarea">
    </textarea>

    <input
      v-else-if="inputType === 'checkbox'"
      :checked="value"
      :value="value"
      :disabled="disabled"
      type="checkbox"
      :id="uniqueId"
      @change="onChange($event)"
      class="input-field__checkbox" />

    <input
      v-else-if="inputType === 'toggle'"
      :checked="value"
      :name="uniqueId"
      :value="value"
      type="radio"
      :id="uniqueId"
      @change="onChange($event)"
      class="input-field__toggle" />

    <input
      v-else-if="inputType === 'radio'"
      :name="selectName"
      :checked="value"
      :value="value"
      type="radio"
      :id="uniqueId"
      @change="onChange($event)"
      class="input-field__radio" />

    <select
      class="input-field__select" v-else-if="inputType === 'select' && !selectSettings.optionTitle"
      :name="selectName" :id="uniqueId" @change="onChange($event)">
      <option disabled hidden selected value=""></option>
      <option
        v-for="(option, index) in selectOptions"
        :key="index"
        :selected="option === value"
        :value="option">
        {{ option }}
      </option>
    </select>

    <select
      v-else-if="inputType === 'select'
      && selectSettings.optionTitle && selectSettings.optionValue"
      class="input-field__select"
      :name="selectName" :id="uniqueId" @change="onChange($event)">
      <option disabled hidden selected value=""></option>
      <option
        v-for="option in selectOptions"
        :key="option[selectSettings.optionKey]"
        :value="option[selectSettings.optionValue]"
        :selected="option[selectSettings.optionValue] === value">
        {{ option[selectSettings.optionTitle] }}
      </option>
    </select>

    <select
      v-else-if="inputType === 'select'
      && selectSettings.optionTitle && !selectSettings.optionValue"
      class="input-field__select"
      :name="selectName" :id="uniqueId" @change="onChange($event)">
      <option disabled hidden selected value=""></option>
      <option
        v-for="option in selectOptions"
        :key="option[selectSettings.optionKey]"
        :value="option"
        :selected="option === value">
        {{ option[selectSettings.optionTitle] }}
      </option>
    </select>

    <div
      v-else-if="inputType === 'autocomplete' && !selectSettings.optionTitle"
      class="input-field__select"
      :name="selectName" :id="uniqueId" @change="onChange($event)">
      autocomplete
    </div>

    <div
      v-else-if="inputType === 'rating'"
      class="input-field__rating">
      <label
        v-for="star in 5"
        :key="star"
        class="input-field__rating-star"
        @mouseover="setActiveIcons(star-1)"
        @mouseleave="setActiveIcons(-1)">
        <input
          class="input-field__rating-input"
          type="radio"
          :name="uniqueId"
          :value="star"
          @change="onChange($event); setActiveIcons(star-1, true)"/>
        <span
          class="input-field__rating-icon"
          :class="{
            'input-field__rating-icon-active': activeIcons[star-1],
            'input-field__rating-icon-hover': hoverIcons[star-1]
          }">★</span>
      </label>
      <div class="input-field__rating-star-number">{{ activeScore === undefined ? '' : activeScore + 1 }}</div>
    </div>

    <iconComponent
      v-if="inputType === 'select'" width="16px" class="input-field__select-icon"
      symbol="forward" />

    <input
      v-else-if="inputType === 'text' || inputType === 'number' || inputType === 'email' || inputType === 'password' || inputType === 'url'"
      :type="inputType"
      :id="id"
      :placeholder="placeholder"
      :value="value"

      :pattern="customValidation()"

      @focus="onFocus"
      @blur="focus = false"

      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :required="required"

      :class="computedClasses"

      :style="color ? {'background': color[0], 'border-color': color[1]} : ''"

      @input="onChange($event)"
      @keypress="inputType === 'number' ? isNumber($event) : ''"
      class="--input" />

    <div class="input-field__helper-line" v-if="helperText || errorText">
      <div v-if="helperText" class="input-field__helper-text">{{ helperText }}</div>
      <div v-if="errorText" class="input-field__error-text">{{ errorText }}</div>
    </div>

    <label v-if="label && (inputType === 'toggle' || inputType === 'radio')" class="--toggle-label --radio-label">
      {{ label }}
    </label>
    <label
           v-else-if="label"
           class="--label"
           :class="labelClasses"
           :for="uniqueId"
           ref="label">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "InputFieldComponent",
  components: {},
  data() {
    return {
      valueCopy: null,
      focus: false,
      valid: true,
      typeOfInput: ['text', 'number', 'textarea', 'email', 'password', 'url'],
      charLimitReached: this.labelLengthLimit,
      selectedOption: {},
      activeIcons: [false, false, false, false, false],
      hoverIcons: [false, false, false, false, false],
      activeScore: undefined
    };
  },
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object],
      required: false,
      default: () => {

      },
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    selectName: {
      type: String,
      required: false,
      default: '',
    },
    selectOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * optionTitle = the name of the key to the option title
     * optionValue = the name of the key to the option value
     * optionKey = the name of the key to a unique key
     */
    selectSettings: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    errorText: {
      type: [Array, String],
      required: false,
      default: null,
    },
    helperText: {
      type: String,
      required: false,
      default: '',
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    filled: {
      type: Boolean,
      required: false,
      default: true,
    },
    iconFilled: {
      type: Boolean,
      required: false,
      default: false,
    },
    static: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: ' ',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationType: {
      type: String,
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    leadingIcon: {
      type: String,
      required: false,
      default: '',
    },
    clickableLeadingIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    trailingIcon: {
      type: String,
      required: false,
      default: '',
    },
    clickableTrailingIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    leadingIconStroke: {
      required: false,
      type: String,
      default: 'black',
    },
    leadingIconFill: {
      required: false,
      type: String,
      default: 'black',
    },
    trailingIconStroke: {
      required: false,
      type: String,
      default: 'black',
    },
    trailingIconFill: {
      required: false,
      type: String,
      default: 'black',
    },
    single: {
      required: false,
      type: Boolean,
      default: false,
    },
    color: {
      required: false,
      type: Array,
    },
    id: {
      required: false,
      type: String,
      default: '',
    },
    errorMessages: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
    },
    uniqueId() {
      return `${this.inputType} ${this.label}${this.id}`;
    },
    computedClasses() {
      return {
        '--active': this.focus,
        '--disabled': this.disabled,
        '--filled': this.filled && !this.outlined,
        '--outlined': this.outlined && !this.filled,
        '--static': this.static && !this.outlined && !this.filled,
        '--has-errors': !this.valid || this.errorMessages.length !== 0,
        '--focused': this.focus,
        '--has-icon': this.trailingIcon !== '' || this.leadingIcon !== '',
        '--no-leading-icon': this.leadingIcon != '',
        '--no-icon': this.trailingIcon === '' && this.leadingIcon === '',
        '--single': this.single,
      };
    },
    labelClasses() {
      return {
        '--static-top': this.charLimitReached || this.inputType === 'textarea',
        '--float-above-filled': this.filled && !this.charLimitReached && (this.inputType !== 'checkbox') && (this.inputType !== 'toggle') && (this.inputType !== 'textarea') && (this.focus || this.value || this.value === 0 || this.placeholder !== ' ') || this.inputType === 'select',
        '--float-above': !this.filled && !this.static && (this.focus || this.placeholder || this.value || this.value === 0) && (this.inputType !== 'checkbox') && (this.inputType !== 'textarea') && !this.charLimitReached,
        '--float-above-static': this.static && (this.focus || this.value !== 0 || this.placeholder) && !this.charLimitReached,
        '--float-below': (!this.focus && !this.value && this.value !== 0) && (this.placeholder === ' ' || !this.placeholder) && (this.inputType !== 'select' && this.inputType !== 'toggle' && this.inputType !== 'checkbox' && this.inputType !== 'textarea') && !this.charLimitReached,
      }
    },
    containerClasses() {
      return {
        '--container-static-label': this.charLimitReached,
        '--container-filled': this.filled && !this.outlined && this.typeOfInput.includes(this.inputType),
        '--container-outlined': this.outlined && !this.filled && !this.static && this.typeOfInput.includes(this.inputType),
        '--container-static': this.static && !this.outlined && !this.filled && (this.inputType === 'text' || this.inputType === 'number'),
        '--container-select': this.inputType === 'select',
        '--container-checkbox': this.inputType === 'checkbox',
        '--container-toggle': this.inputType === 'toggle',
        '--container-radio': this.inputType === 'radio',
        '--container-text-area': this.inputType === 'textarea',
        '--container-rating': this.inputType === 'rating',
        '--container-focused': this.outlined && (this.focus || this.value) && (this.inputType !== 'checkbox' || this.inputType !== 'toggle' || this.inputType !== 'select'),
        '--container-focused-filled': this.filled && (this.focus || this.value) && (this.inputType !== 'checkbox' || this.inputType !== 'toggle' || this.inputType !== 'select' && this.inputType !== 'textarea'),
        '--container-focused-static': this.static && (this.focus || this.value),
        '--container-has-icon': this.trailingIcon !== '' || this.leadingIcon !== '',
        '--container-no-leading-icon': this.leadingIcon === 'none',
        '--container-required': this.required,
      };
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  mounted() {
    this.labelLengthLimit();
  },
  methods: {
    keyListener(event) {
      if (event.key === 'Enter') {
        this.$emit('enterKey');
      }
    },
    onChange(event) {
      let value = undefined;

      if (event.target.type === "checkbox") {
        value = event.target.checked;
      } else if (event.target.type === 'select') {
        value = event.target.selected;
      } else if (this.inputType === 'select' && !this.selectSettings.optionTitle) {
        value = event.target.value;
      } else if (this.inputType === 'select' && this.selectSettings.optionTitle && this.selectSettings.optionValue) {
        value = this.selectOptions[event.target.selectedIndex-1][this.selectSettings.optionValue];
      } else if (this.inputType === 'select' && this.selectSettings.optionTitle && !this.selectSettings.optionValue) {
        value = this.selectOptions[event.target.selectedIndex-1];
      } else {
        value = event.target.value;
      }
      this.$emit("input", value);
    },
    onFocus() {
      if (!this.static) {
        this.focus = true;
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    customValidation() {
      if (this.validationType === 'textOnly') {
        // return '^[a-zA-Z]+$';
        return '^[a-zA-Z-]+$';
      } else if (this.validationType === 'numberOnly') {
        return '^[0-9]*$';
      } else if (this.validationType === 'noNonSpaceChar') {
        // eslint-disable-next-line no-useless-escape
        return '^(?!\s*$).+'
      } else {
        return this.validationType;
      }
    },
    labelLengthLimit() {
      const element = this.$refs.label;
      if(element && element.textContent.length > 40) {
        this.charLimitReached = true;
      } else {
        this.charLimitReached = false;
      }
    },
    setActiveIcons(index, focus = false) {
      if (focus) {
        this.activeScore = index;
        this.activeIcons = this.activeIcons.map((_, i) => i <= index);
      } else {
        this.hoverIcons = this.hoverIcons.map((_, i) => i <= index);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import './InputField.scss';
</style>
