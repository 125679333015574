<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <EntityEditor
      v-if="activation && activation._id"
      :title= "`Change activation '${ activation.name }' (${ activation.company.name })`"
      :admin="true"
      :entity="activation"
      @eventshowCloneModal="showModalCloneActivation(true)"
      entity-type="PVActivation"/>

    <div v-if="activation && activation._id">

      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; grid-gap: 10px; padding: 10px; background: white;">
        <h1 class="md-title">{{ activation.receiptsLength }} Receipts</h1>
        <h1 class="md-title">{{ activation.receiptsCtaDoneLength }} CTA_DONE</h1>
        <h1 class="md-title">{{ activation.limitedCtas }} Unclaimed Codes</h1>
        <span class="btn btn-activate" @click="showListModal = true">Get List</span>
        <span class="btn btn-activate" @click="show3rdPartyListModal = true">Get 3rd party List</span>
        <span class="btn btn-activate" @click="showStatsModal = true">Get Stats</span>
        <span class="btn btn-activate" @click="showCTAModal = true">Get CTA's</span>
        <a
          class="btn btn-activate"  
          :href="`${windowOrigin}/admin/pv/receipts?acti=${activation._id}`">
          Find Receipts
        </a>
      </div>

      <modal
        v-if="showCloneModal"
        :header="{text: `Create copies of '${activation.name}'`, textPosition: 'center', bgColor: '#F2F2F2'}"
        class="modal-view"
        modalType="fullscreen">
        <slot>
          <form novalidate class="md-layout" @submit.prevent="onSubmit">
            <div v-for="(value, index) in clones" :key="index" class="md-layout-item md-small-size-100">
              <md-card>
                <md-card-content>
                  <md-field>
                    <label>Name:</label>
                    <md-input v-model="value.name"></md-input>
                  </md-field>
                  <md-field>
                    <label>Info:</label>
                    <md-input v-model="value.info"></md-input>
                  </md-field>
                  <md-field>
                    <label>Market:</label>
                    <md-select v-model="value.market">
                      <md-option
                        v-for="country in countries" v-bind:key="country.key"
                        :value="country.value">
                        {{country.title}}
                      </md-option>
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>Company:</label>
                    <md-select v-model="value.company_id">
                      <md-option
                        v-for="company in companies" v-bind:key="company._id"
                        :value="company._id">
                        {{company.name}}
                      </md-option>
                    </md-select>
                  </md-field>
                </md-card-content>

                <md-card-actions>
                  <button @click="removeClone(index)">
                    <md-icon style="color: #f44336 !important;">delete</md-icon>
                  </button>
                </md-card-actions>
              </md-card>
            </div>
          </form>
          <button class="btn btn-activate" @click.prevent="addNewClone">Add more copies</button>
          <div>
            <button class="btn btn-transparent" @click="dismissCloneModal()">Cancel</button>
            <button class="btn btn-activate" @click="createClones()">Save {{ clones.length }} copies</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="showListModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showListModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get List' }">
        <slot>
          <h3>Filters</h3>
          <md-datepicker
            v-model="createdStart">
            <label>Created Start</label>
          </md-datepicker>
          <md-datepicker
            v-model="createdEnd">
            <label>Created End</label>
          </md-datepicker>
          <md-field>
            <label for="cta_done">cta_done?</label>
            <md-select
              v-model="ctaDone">
              <md-option value="all">Both</md-option>
              <md-option :value="true">cta_done == true</md-option>
              <md-option :value="false">cta_done == false</md-option>
            </md-select>
          </md-field>
          <md-field>
            <label for="administrated">administrated?</label>
            <md-select
              v-model="administrated">
              <md-option value="all">Both</md-option>
              <md-option :value="true">administrated == true</md-option>
              <md-option :value="false">administrated == false</md-option>
            </md-select>
          </md-field>
          <h3>Change selection</h3>
          <md-field>
            <label for="administrated">Set Administrated</label>
            <md-select
              v-model="setAdministrated">
              <md-option value="all">Set all</md-option>
              <md-option value="cta_done">Set all (cta_done == true)</md-option>
              <md-option :value="false">Don't set</md-option>
            </md-select>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getList()">Download List</button>
            <button class="btn btn-transparent" @click="showListModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="show3rdPartyListModal"
        size="xlarge"
        modalType="fullscreen"
        @close="show3rdPartyListModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get 3rd party List' }">
        <slot>
          <div style="text-align: left;">
            <h3>Important!</h3>
            <p>When you click ”download this list”, the following is happening:</p>
            <p>This list will contain receipts of:</p>
            <ul>
              <li>All receipts with CTA_Done == True (meaning that they have all completed the registration)</li>
              <li>All receipts with administrated == False (meaning that they have not been administrated before)</li>
              <li>The receipts you get will be set to administrated = true</li>
            </ul>
            <p>And the following action will be taken on the receipts in this list:</p>
            <ul>
              <li>The receipts in the list you get will be set to administrated == True (meaning that we have handled this list of consumers)</li>
            </ul>
            <p>So it is important to take action on the list you take out since they will be set as administrated in Enginio.</p>
            <p>All of this means that the next time you click the same button, you will not get the same consumers in the list, since they have already been administrated. The new list will contain all new receipts with CTA_Done == True and all receipts with administrated == False.</p>
          </div>
          <div>
            <button class="btn btn-activate" @click="get3rdPartyList()">Download List</button>
            <button class="btn btn-transparent" @click="show3rdPartyListModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="showStatsModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showStatsModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get Stats' }">
        <slot>
          <h3>Do you want to filter on a store?</h3>
          <md-field>
            <label>Store</label>
            <md-input v-model="receiptStore"></md-input>
          </md-field>
          <md-field>
            <label>Top Stores, per store address</label>
            <md-input v-model="receiptStoreTop" placeholder="20"></md-input>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getStats()">Download Stats</button>
            <button class="btn btn-transparent" @click="showStatsModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="showCTAModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showCTAModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get CTA\'s' }">
        <slot>
          <md-field>
            <label for="administrated">won?</label>
            <md-select
              v-model="won">
              <md-option value="all">Both</md-option>
              <md-option :value="true">won == true</md-option>
              <md-option :value="false">won == false</md-option>
            </md-select>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getUnclaimed()">Download CTA's</button>
            <button class="btn btn-transparent" @click="showCTAModal = false">Close</button>
          </div>
        </slot>
      </modal>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import countries from '../assets/countries.json';

export default {
  name: "AdminPVActivation",
  components: {
    EntityEditor,
  },
  mixins: [BeforeEnterMixin],
  props: ['activationId'],
  mounted() {
    this.$store.dispatch('pvActivations/getAdminPVActivation', { activationId: this.activationId }).then(() => {
      this.loadedPage = true;
      if (this.isDeveloping && this.activation.market
        && !this.activation.market.includes('local') && !this.activation.market.includes('test')
        && !this.activation.market.includes('dev') && !this.activation.market.includes('default')) {
        this.setAlert(`Activation market is "${this.activation.market}", make sure that it is the same as process.env.DEFAULT_MARKET!`, 10);
      }
      if (!this.activation.campaign && this.activation.cashback_step) {
        this.setAlert(`No Campaign is set, very important for cashback campaigns!`, 8);
      }
    });
  },
  computed: {
    ...mapGetters('pvActivations', ["activation"]),
    ...mapGetters('pvCompanies', ["companies"]),
    prefilledClone() {
      return {
        name: "Copy of " + this.activation.name,
        market: this.activation.market,
        company_id: this.activation.company._id,
        info: this.activation.info
      };
    },
  },
  data() {
    return {
      loadedPage: false,
      receiptId: '',
      search: '',
      showReceiptModal: false,
      showOfferModal: false,
      showCloneModal: false,
      parentReceiptId: '',
      showListModal: false,
      show3rdPartyListModal: false,
      ctaDone: 'all',
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
      administrated: 'all',
      setAdministrated: false,
      showStatsModal: false,
      won: 'all',
      showCTAModal: false,
      receiptStore: '',
      receiptStoreTop: 20,
      clones: [],
      countries,
    };
  },
  methods: {
    getList() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/receipts/csv`
        +`?createdStart=${this.createdStart/1000}&createdEnd=${this.createdEnd/1000 + 3600*24}&setAdministrated=${this.setAdministrated}&administrated=${this.administrated}&ctaDone=${this.ctaDone}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.activation.name}_receipts_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    get3rdPartyList() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/receipts/3rdparty/csv`
        +`?setAdministrated=cta_done&administrated=false&ctaDone=true`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `thirdparty_${this.activation.name}_receipts_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getStats() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/statistics/csv?store=${this.receiptStore}&top=${this.receiptStoreTop}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.activation.name}_statistics_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getUnclaimed() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/limited-ctas/csv?won=${this.won}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.activation.name}_unclaimed_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getCompanies() {
      this.$store.dispatch('pvCompanies/findAdminPVCompanies');
    },
    showModalCloneActivation(show) {
      // Get list of all companies so admin can clone activation to another company
      this.getCompanies();

      //pre-fill at least 1 clone for a nice UX
      let starterClone = { ...this.prefilledClone };
      this.clones = [starterClone];
      this.showCloneModal = show;
    },
    dismissCloneModal() {
      // don't leave any left-overs
      this.clones = [];
      //close the dialog
      this.showCloneModal = false;
    },
    addNewClone() {
      // Cloning the Object with Spread Operator
      let exampleClone = { ...this.prefilledClone };
      this.clones.push(exampleClone);
    },
    removeClone(index) {
      this.clones.splice(index, 1);
    },
    createClones() {
      this.$store
      .dispatch("pvActivations/cloneAdminPVActivation", { entityId: this.activationId, clones: this.clones})
      .then((nrOfCreatedCopies) => {
        this.dismissCloneModal();
        this.setAlert("Succesfully created " + nrOfCreatedCopies + " copies!");
        this.$router.back();
      }).catch((err) => {
        this.setAlert("Failed to create copies with error: " + err);
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .company-item-image{
      width: fit-content;
      height: fit-content;
    }
    .company-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.company-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-company-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
